import React from 'react';
import block from 'bem-cn-lite';
import Stack from '@mui/material/Stack';
import { Button, Icon, TextInput, useToaster } from '@gravity-ui/uikit';

import './ProfileContact.scss';
import {Layout} from '../../components/layout';
import { ContactCard } from '../../components/contact-card';

import { ReactComponent as PhoneIcon} from '../../assets/icons/phone-small.svg';
import { ReactComponent as MailIcon} from '../../assets/icons/mail-small.svg';
import { ReactComponent as TelegramIcon} from '../../assets/icons/telegram-small.svg';
import { ReactComponent as WhatsAppIcon} from '../../assets/icons/whatsapp-small.svg';
import { clearFormattedPhoneNumber, formatPhoneNumber, isPhoneNumberValid } from '../../utils/phonenumber';
import { isDigitOrEmpty } from '../../utils/validation';
import { useLazyGetUserQuery, useUpdateUserMutation } from '../../store/api/auth';
import { PrimaryButton } from '../../components/button';
import { ChevronLeft, CircleCheckFill, CircleInfoFill, CircleXmarkFill } from '@gravity-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { NavigationPath } from '../../utils/constant/navigation';

const b = block('profile-contact-page');

interface UserInfo {
  name: string,
  email: string,
  phoneNumber: string,
  whatsapp: string,
  clearWhatsapp: string,
  clearPhoneNumber: string,
  telegram: {
    clearPhoneNumber: string,
    phoneNumber: string,
    username: string,
  }
}

interface OpenedContacts {
  email: boolean,
  phoneNumber: boolean,
  whatsapp: boolean,
  telegram: boolean
}

export const ProfileContactPage: React.FC = () => {
  const navigate = useNavigate();
  const {add} = useToaster();
  const [getUser, {
    data: getUserResponse,
    ...getUserRequestInfo
  }] = useLazyGetUserQuery();

  const [updateUser, {
    data: updateUserResponse,
    ...updateUserRequestInfo
  }] = useUpdateUserMutation();

  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const [userInfo, setUserInfo] = React.useState<UserInfo>({
    name: "",
    email: "",
    phoneNumber: "",
    clearPhoneNumber: "",
    whatsapp: "",
    clearWhatsapp: "",
    telegram: {
      clearPhoneNumber: "",
      phoneNumber: "",
      username: "",
    }
  });
  const [openedContacts, setOpenedContacts] = React.useState<OpenedContacts>({
    email: false,
    phoneNumber: false,
    telegram: false,
    whatsapp: false
  })

  const handleOnUserInfoChange = (value: {[key: string]: any}) => {
    setUserInfo({...userInfo, ...value});
  }

  const handleOnUpdateUserButtonClick = () => {

    const { clearPhoneNumber, clearWhatsapp, telegram, ...body } = userInfo;
    if (clearPhoneNumber && !isPhoneNumberValid(clearPhoneNumber)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }

    if (clearWhatsapp && !isPhoneNumberValid(clearWhatsapp)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона Whatsapp`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }

    if (telegram.clearPhoneNumber && !isPhoneNumberValid(telegram.clearPhoneNumber)) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Неправильный формат номера телефона Telegram`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
      });
      return;
    }


    let updateUserBody: any = {
      ...body,
      phoneNumber: clearPhoneNumber ? "+" + clearPhoneNumber : null,
      whatsapp: clearWhatsapp ? "+" + clearWhatsapp : null,
      telegram: {
        phoneNumber: telegram.clearPhoneNumber 
          ? "+" + telegram.clearPhoneNumber
          : null,
        username: telegram.username
      }
    }

    updateUserBody = Object.keys(updateUserBody).reduce((prev: any, curr: string) => {
      if (curr === "telegram") {
        if (updateUserBody[curr].username || updateUserBody[curr].phoneNumber) {
          return ({...prev, [curr]: updateUserBody[curr]});
        } else {
          return prev;
        }
      } else {
        if (updateUserBody[curr]) {
          return ({...prev, [curr]: updateUserBody[curr]});
        } else {
          return prev;
        }
      }
    }, {});

    updateUser(updateUserBody);
  };

  React.useEffect(() => {
    getUser();
  }, [])

  React.useEffect(() => {
    if (getUserRequestInfo.isSuccess && getUserResponse) {
      setUserInfo({
        phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || "")),
        clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.phoneNumber || ""),
        email: getUserResponse?.result.email || "",
        name: getUserResponse?.result.name || "",
        whatsapp: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.whatsapp || "")),
        clearWhatsapp: clearFormattedPhoneNumber(getUserResponse?.result.whatsapp || ""),
        telegram: {
          phoneNumber: formatPhoneNumber(clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || "")),
          clearPhoneNumber: clearFormattedPhoneNumber(getUserResponse?.result.telegram?.phoneNumber || ""),
          username: getUserResponse?.result.telegram?.username || ""
        }
      });
      setOpenedContacts({
        phoneNumber: Boolean(getUserResponse?.result.phoneNumber),
        telegram: Boolean(getUserResponse?.result.telegram),
        whatsapp: Boolean(getUserResponse?.result.whatsapp),
        email: Boolean(getUserResponse?.result.email)
      })
    }
  }, [getUserRequestInfo.isSuccess, getUserResponse])

  React.useEffect(() => {
    if (updateUserRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: updateUserRequestInfo.error.error.message,
      });
    }
  }, [updateUserRequestInfo.isError, updateUserRequestInfo.error])

  React.useEffect(() => {
    if (updateUserRequestInfo.isSuccess) {
      add({
        name: 'success',
        autoHiding: 3000,
        isClosable: true,
        title: `Изменения успешно внесены`,
        renderIcon: () => <Icon className="success-green" data={CircleCheckFill} size={18} />,
      });
      navigate(NavigationPath.MainPage);
    }
  }, [updateUserRequestInfo.isSuccess])

  return (
    <Layout 
      navigationBar={true}
      header={
        <Stack 
          position='relative'
          width='100%'
          alignItems='center'
          justifyContent='center'
          sx={{
            padding: "24px 8px 16px 8px"
          }}
        >
          <Button 
            view='flat'
            onClick={() => navigate(-1)} 
            className={b('back-button')}
          >
            <Icon data={ChevronLeft} size={22} />
          </Button>
          <span className='fs17-primary-bold'>Контакты</span>
          
        </Stack>
      }
      sxLayout={{
        paddingBottom: '70px'
      }}
    >
      <div className={b()}>
        <h3 className='fs20-primary-bold'>
          Что покажем нашедшему?
        </h3>
        <Stack 
          direction='column' 
          spacing={2}
          flexGrow={1}
          width='100%'
          // overflow='auto'
          px={0.2}
          pt={1}
          pb={2}
        >
          <ContactCard Icon={PhoneIcon} title='Номер телефона' open={openedContacts.phoneNumber}>
            <TextInput
              size='xl'
              value={userInfo.phoneNumber}
              onChange={(e: any) => {
                const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                if (isDigitOrEmpty(clearedPhoneNumber)) {
                  handleOnUserInfoChange({ 
                    phoneNumber: formatPhoneNumber(clearedPhoneNumber),
                    clearPhoneNumber: clearedPhoneNumber,
                  })}
                }
              }
            />
          </ContactCard>
          <ContactCard 
            Icon={TelegramIcon} 
            title='Telegram' 
            open={openedContacts.telegram}
            onOpen={() => {
              if (!userInfo.telegram.phoneNumber && userInfo.clearPhoneNumber) {
                handleOnUserInfoChange({ 
                  telegram: {
                    phoneNumber: formatPhoneNumber(userInfo.clearPhoneNumber),
                    clearPhoneNumber: userInfo.clearPhoneNumber,
                    username: userInfo.telegram.username
                  }
                })
              }
            }}
          >
            <TextInput
              size="xl"
              value={userInfo.telegram.phoneNumber}
              placeholder='+7 (999) 999-99-99'
              onChange={(e: any) => {
                const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                if (isDigitOrEmpty(clearedPhoneNumber)) {
                  handleOnUserInfoChange({ 
                    telegram: {
                      phoneNumber: formatPhoneNumber(clearedPhoneNumber),
                      clearPhoneNumber: clearedPhoneNumber,
                      username: userInfo.telegram.username
                    }
                  })
                }
              }}
            />
            <TextInput
              size="xl"
              value={userInfo.telegram.username}
              placeholder='maxlick'
              endContent={<Tooltip
                title="username должен быть без @"
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                slotProps={{
                  popper: {
                    disablePortal: true,
                  },
                }}
              >
                <Button view='flat' onClick={handleTooltipOpen}>
                  <Icon data={CircleInfoFill} size={18} />
                </Button>
              </Tooltip>}
              onChange={(e: any) => handleOnUserInfoChange({ 
                telegram: {
                  username: e.target.value,
                  phoneNumber: formatPhoneNumber(userInfo.telegram.clearPhoneNumber),
                  clearPhoneNumber: userInfo.telegram.clearPhoneNumber,
                } })}
            />
          </ContactCard>
          <ContactCard Icon={MailIcon} title='Почта' open={openedContacts.email}>
            <TextInput
              size="xl"
              value={userInfo.email}
              onChange={(e: any) => handleOnUserInfoChange({ email: e.target.value })}
            />
          </ContactCard>
          <ContactCard 
            Icon={WhatsAppIcon} 
            title='WhatsApp' 
            open={openedContacts.whatsapp}
            onOpen={() => {
              if (!userInfo.whatsapp && userInfo.clearPhoneNumber) {
                handleOnUserInfoChange({ 
                  whatsapp: formatPhoneNumber(userInfo.clearPhoneNumber),
                  clearWhatsapp: userInfo.clearPhoneNumber,
                })
              }
            }}
          >
            <TextInput
              size="xl"
              placeholder='+7 (999) 999-99-99'
              value={userInfo.whatsapp}
              onChange={(e: any) => {
                const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
                if (isDigitOrEmpty(clearedPhoneNumber)) {
                  handleOnUserInfoChange({ 
                    whatsapp: formatPhoneNumber(clearedPhoneNumber),
                    clearWhatsapp: clearedPhoneNumber,
                  })
                }
              }}
            />
          </ContactCard>
        </Stack>

        <PrimaryButton size='xl' onClick={handleOnUpdateUserButtonClick}>
          Сохранить
        </PrimaryButton>
      </div>

      
    </Layout>
  );
};