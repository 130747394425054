import React from 'react';
import block from 'bem-cn-lite';

import './Auth.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavigationPath} from '../../utils/constant/navigation';
import { Stack } from '@mui/material';

import { Layout } from '../../components/layout';
import { Box, TextInput } from '@gravity-ui/uikit';
import { InputLabel } from '../../components/input-label';
import { PrimaryButton } from '../../components/button';
import { isDigit, isDigitOrEmpty } from '../../utils/validation';
import { clearFormattedPhoneNumber, formatPhoneNumber } from '../../utils/phonenumber';
import { useLoginMutation } from '../../store/api/auth';
import { Link } from 'react-router-dom';

const b = block('auth-page');

export const AuthPage: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [toLogin, {
    data: loginData, 
    ...loginRequestInfo
  }] = useLoginMutation();

  const [error, setError] = React.useState('');
  const [phoneOrEmail, setPhoneOrEmail] = React.useState('');
  const [clearPhone, setClearPhone] = React.useState("");

  const handleOnChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      if (isDigit(value.at(0)) || value.startsWith("+")) {
        const clearedPhoneNumber = clearFormattedPhoneNumber(e.target.value);
        if (isDigitOrEmpty(clearedPhoneNumber)) {
          setClearPhone(clearedPhoneNumber)
          const formattedPhoneNumber = formatPhoneNumber(clearedPhoneNumber);
          setPhoneOrEmail(formattedPhoneNumber);
        }
      } else {
        setPhoneOrEmail(value);
      } 
    } else {
      setPhoneOrEmail("");
      setClearPhone("");
    }
    
  };

  const [isLoginButtonClicked, setIsLoginButtonClicked] = React.useState(false);

  const handleOnClickLoginButton = () => {
    if (phoneOrEmail.startsWith("+")) {
      toLogin({ login: "+" + clearPhone});
    } else {
      toLogin({ login: phoneOrEmail});
    }
  };

  const handleOnPressEnter = (e: any) => {
    if (e.charCode === 13) handleOnClickLoginButton();
  };

  React.useEffect(() => {
    if (loginRequestInfo.isSuccess) {
      setIsLoginButtonClicked(false);
      navigate(NavigationPath.SendCodePage, { state: {
        phoneOrEmail: phoneOrEmail.startsWith("+") ? clearPhone : phoneOrEmail,
        isPhone: phoneOrEmail.startsWith("+"),
        nextPage: state?.nextPage || ""
      } });
    }
  }, [loginRequestInfo.isSuccess])


  React.useEffect(() => {
    if (loginRequestInfo.isError) {
      setIsLoginButtonClicked(false);
      // @ts-ignore
      setError(loginRequestInfo.error.error.message)
    }
  }, [loginRequestInfo.isError])

  return (
    <Layout loading={loginRequestInfo.isLoading}>
      <Stack className={b()}>

        <h1 className='fs24-primary-bold'>
          Войти или создать аккаунт
        </h1>
        <p className='fs15-secondary-thin'>
          Введите свой номер телефона или почту
          и мы отправим код для подтверждения
        </p>
        <Box className={b('form')}>
          <InputLabel labelText="Телефон или почта">
            <TextInput
              size="xl"
              autoFocus
              value={phoneOrEmail}
              onChange={handleOnChangePhone}
              onKeyPress={handleOnPressEnter}
              errorMessage={error || ""}
              validationState={loginRequestInfo.isError ? "invalid" : undefined}
            />
          </InputLabel>
          <PrimaryButton 
            size='xl'
            loading={isLoginButtonClicked}
            disabled={!phoneOrEmail}
            onClick={handleOnClickLoginButton}
          >
            Получить код
          </PrimaryButton>
        </Box>
        <Stack
          direction='row'
          alignItems='center' 
          justifyContent='center'
          className={b('policy')}
        >
          <p>
            Продолжая, вы соглашаетесь с
            <Link to={NavigationPath.PrivacyPolicyPage} className='link'>
              Политикой конфиденциальности
            </Link>
            и
            <Link to='#' className='link'>
              Пользовательскими соглашениями
            </Link>
          </p>
        </Stack>

      </Stack>
    </Layout>
  );
};
