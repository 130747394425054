import React from 'react';
import block from 'bem-cn-lite';
import { Box } from '@mui/material';


import './ImgWrapper.scss';


const b = block('img-wrapper');

interface ImgWrapperProps {
  src: string,
  width: number | string,
  height: number | string,
}

export const ImgWrapper: React.FC<ImgWrapperProps> = ({
  src,
  height,
  width
}) => (
  <Box className={b()} width={width} height={height}>
    <img src={src} />
  </Box>
);
