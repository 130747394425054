import React from 'react';
import block from 'bem-cn-lite';
import './Tag.scss';
import {Layout} from '../../components/layout';

import { Stack } from '@mui/material';
import { Spin, Text } from '@gravity-ui/uikit';
import { useNavigate, useParams } from 'react-router-dom';
import { Logo } from '../../components/logo';
import { PreviewBanner } from '../../components/preview-banner';
import { useGetTagByIdQuery } from '../../store/api/tag';
import { PreviewLink } from '../../components/preview-link';

import { ReactComponent as PhoneIcon} from '../../assets/icons/phone.svg';
import { ReactComponent as MailIcon} from '../../assets/icons/mail.svg';
import { ReactComponent as TelegramIcon} from '../../assets/icons/telegram.svg';
// import { ReactComponent as ViberIcon} from '../../assets/icons/viber.svg';
// import { ReactComponent as VKIcon} from '../../assets/icons/vk.svg';
import { ReactComponent as WhatsAppIcon} from '../../assets/icons/whatsapp.svg';
import { NavigationPath } from '../../utils/constant/navigation';
import { TagStatus } from '../../store/api/tag/types/models';
import { Link } from 'react-router-dom';

const b = block('tag-page');

export const TagPage: React.FC = () => {
  const { tagId } = useParams<{tagId: string}>();
  const navigatge = useNavigate();

  
  const {
    data: getTagByIdResponse, 
    ...getTagByIdRequestInfo
  } = useGetTagByIdQuery(tagId!);

  const validTelegramName = (name: string) => name.startsWith("@") ? name.slice(1) : name;

  React.useEffect(() => {
    if (
      getTagByIdRequestInfo.isError && 
      // @ts-ignore
      getTagByIdRequestInfo?.error?.status === 404
    ) {
      navigatge(`${NavigationPath.InacitveTagPage}`)
    }
  }, [getTagByIdRequestInfo.isError, getTagByIdRequestInfo.error])

  React.useEffect(() => {
    if (getTagByIdRequestInfo.isSuccess && getTagByIdResponse) {
      if (getTagByIdResponse.result.status === TagStatus.STORED) {
        navigatge(`${NavigationPath.LinkTagPage}/${getTagByIdResponse.result.id}`);
      }
    }
  }, [getTagByIdRequestInfo.isSuccess, getTagByIdResponse])

  return (
    <Layout
      // layoutBgColor='#ffffff'
      sxContentMain={{
        padding: '16px 0'
      }}
      header={
        getTagByIdRequestInfo.isSuccess
        ? <Stack 
            direction='column' 
            alignItems='flex-start' 
            width='100%'
            px={2}
            pb={2}
            pt={3}
          >
            <Link to='/'>
              <Logo />
            </Link>
            <PreviewBanner
              cost={getTagByIdResponse?.result.reward}
              name={getTagByIdResponse?.result.ownerName || ""}
              description={getTagByIdResponse?.result.description || ""}
            />
          </Stack>
        : <></>
      }
    >
      {getTagByIdRequestInfo.isLoading
        ? <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
            <Spin size='xl' className='spin' />
          </Stack>
        : null
      }

      {getTagByIdRequestInfo.isSuccess
        ? <div className={b()}>
            <Text
              color='secondary' 
              style={{
                fontWeight: 500,
                padding: "0 12px"
              }}
            >
              КОНТАКТЫ ВЛАДЕЛЬЦА
            </Text>
      
            <Stack 
              spacing={2} 
              mt={2}
              px={1.5}
              py={1}
              maxHeight={400}
              width='100%'
              height='100%'
              overflow='auto'
            >
              {getTagByIdResponse?.result.telegram
                ? <PreviewLink 
                    href={`https://t.me/${
                      getTagByIdResponse?.result.telegram.username
                        ? validTelegramName(getTagByIdResponse?.result.telegram.username)
                        : getTagByIdResponse?.result.telegram.phoneNumber
                      }`
                    } 
                    label='Telegram'
                    textContent={
                      <>
                        <p className='fs13-secondary-thin'>
                          {getTagByIdResponse?.result.telegram.phoneNumber}
                        </p>
                        <p className='fs13-secondary-thin'>
                          {validTelegramName(getTagByIdResponse?.result.telegram.username || "")}
                        </p>
                      </>
                    } 
                    LeftIcon={TelegramIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.whatsapp
                ? <PreviewLink 
                    href={`https://wa.me/${getTagByIdResponse?.result.whatsapp}`} 
                    label='WhatsApp' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.whatsapp}
                      </p>
                    }
                    LeftIcon={WhatsAppIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.phoneNumber
                ? <PreviewLink 
                    href={`tel:${getTagByIdResponse?.result.phoneNumber}`} 
                    label='Телефон' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.phoneNumber}
                      </p>
                    }
                    LeftIcon={PhoneIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.email
                ? <PreviewLink 
                    href={`mailto:${getTagByIdResponse?.result.email}`} 
                    label='Почта' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.email}
                      </p>
                    }
                    LeftIcon={MailIcon} 
                  />
                : null
              }
            </Stack>
          </div>
        : null
      }
      
    </Layout>
  );
};