import React from 'react';
import block from 'bem-cn-lite';
import { Button, Icon, Sheet, Spin, Text, useToaster } from '@gravity-ui/uikit';

import './PreviewSheet.scss';
import { Logo } from '../logo';
import { Layout } from '../layout';
import { Stack } from '@mui/material';
import { PreviewBanner } from '../preview-banner';
import { PreviewLink } from '../preview-link';
import { CircleXmarkFill, Xmark } from '@gravity-ui/icons';

import { ReactComponent as PhoneIcon} from '../../assets/icons/phone.svg';
import { ReactComponent as MailIcon} from '../../assets/icons/mail.svg';
import { ReactComponent as TelegramIcon} from '../../assets/icons/telegram.svg';
// import { ReactComponent as ViberIcon} from '../../assets/icons/viber.svg';
// import { ReactComponent as VKIcon} from '../../assets/icons/vk.svg';
import { ReactComponent as WhatsAppIcon} from '../../assets/icons/whatsapp.svg';
import { useLazyGetTagByIdQuery } from '../../store/api/tag';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../utils/constant/navigation';


const b = block('preview-sheet');

interface PreviewSheetProps {
  tagId: string,
  open: boolean,
  onClose: () => void,
}

export const PreviewSheet: React.FC<PreviewSheetProps> = ({
  tagId,
  open,
  onClose
}) => {
  const {add} = useToaster();
  const navigate = useNavigate();

  const [getTagById, {
    data: getTagByIdResponse, 
    ...getTagByIdRequestInfo
  }] = useLazyGetTagByIdQuery();

  const validTelegramName = (name: string) => name.startsWith("@") ? name.slice(1) : name;

  React.useEffect(() => {
    getTagById(tagId!);
  }, [])

  React.useEffect(() => {
    if (getTagByIdRequestInfo.isError) {
      add({
        name: 'error',
        autoHiding: 3000,
        isClosable: true,
        title: `Ошибка в запросе`,
        renderIcon: () => <Icon className="success-red" data={CircleXmarkFill} size={18} />,
        // @ts-ignore
        content: getTagByIdRequestInfo.error.error.message,
      });
      navigate(NavigationPath.MainPage);
      onClose();
    }
  }, [getTagByIdRequestInfo.isError, getTagByIdRequestInfo.error])

  return (
    <Sheet 
      visible={open}
      onClose={onClose}
      className={b()}
    >
      <Layout
        header={
          getTagByIdRequestInfo.isSuccess
          ? <Stack 
              direction='column' 
              alignItems='flex-start' 
              width='100%'
              px={2}
              pb={2}
            >
              <Logo />
              <PreviewBanner 
                cost={getTagByIdResponse?.result.reward}
                name={getTagByIdResponse?.result.ownerName || ""}
                description={getTagByIdResponse?.result.description || ""}
              />
            </Stack>
          : <></>
        }
      >
        {getTagByIdRequestInfo.isLoading
          ? <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
              <Spin size='xl' className='spin' />
            </Stack>
          : null
        }

        {getTagByIdRequestInfo.isSuccess
          ? <>
            <Text 
              color='secondary' 
              style={{
                fontWeight: 500
              }}
            >
              КОНТАКТЫ ВЛАДЕЛЬЦА
            </Text>
      
            <Stack 
              spacing={2} 
              mt={2}
              px={0.1}
              py={1}
              maxHeight={400}
              height='100%'
              overflow='auto'
            >
              {getTagByIdResponse?.result.telegram
                ? <PreviewLink 
                    href={`https://t.me/${
                      getTagByIdResponse?.result.telegram.username
                        ? validTelegramName(getTagByIdResponse?.result.telegram.username)
                        : getTagByIdResponse?.result.telegram.phoneNumber
                      }`
                    } 
                    label='Telegram'
                    textContent={
                      <>
                        <p className='fs13-secondary-thin'>
                          {getTagByIdResponse?.result.telegram.phoneNumber}
                        </p>
                        <p className='fs13-secondary-thin'>
                          {validTelegramName(getTagByIdResponse?.result.telegram.username || "")}
                        </p>
                      </>
                    } 
                    LeftIcon={TelegramIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.whatsapp
                ? <PreviewLink 
                    href={`https://wa.me/${getTagByIdResponse?.result.whatsapp}`} 
                    label='WhatsApp' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.whatsapp}
                      </p>
                    }
                    LeftIcon={WhatsAppIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.phoneNumber
                ? <PreviewLink 
                    href={`tel:${getTagByIdResponse?.result.phoneNumber}`} 
                    label='Телефон' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.phoneNumber}
                      </p>
                    }
                    LeftIcon={PhoneIcon} 
                  />
                : null
              }

              {getTagByIdResponse?.result.email
                ? <PreviewLink 
                    href={`mailto:${getTagByIdResponse?.result.email}`} 
                    label='Почта' 
                    textContent={
                      <p className='fs13-secondary-thin'>
                        {getTagByIdResponse?.result.email}
                      </p>
                    }
                    LeftIcon={MailIcon} 
                  />
                : null
              }
            </Stack>
            
            <Button
              size='xl'
              width='max'
              onClick={onClose}
              style={{
                margin: '12px 0'
              }}
            >
              Закрыть
              <Icon data={Xmark} size={18} />
            </Button>
          </>
          : null
        }
        
      </Layout>
    </Sheet>
  );
}
