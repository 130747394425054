import React from 'react';
import block from 'bem-cn-lite';
import { Label, Text } from '@gravity-ui/uikit';
import { Box, Stack } from '@mui/material';

import './PreviewBanner.scss';
import { ImgWrapper } from '../img-wrapper';
import giftIcon from '../../assets/gift.png';

const b = block('preview-banner');

interface PreviewBannerProps {
  name: string,
  description: string,
  cost?: number | null,
}

export const PreviewBanner: React.FC<PreviewBannerProps> = ({
  name,
  description,
  cost
}) => (
  <Stack 
    className={b()}
    direction='row'
    alignItems='flex-start'
    justifyContent='flex-start'
  >
    <Box flexGrow={1} pl={1}> 
      <Text variant='body-3'>
        {name}
      </Text>
      <Text color='secondary'>
        {description}
      </Text>
      {cost
        ? <Label 
            theme='success'
            size='m'
          >
            <Stack direction='row' alignItems='center'>
              <ImgWrapper src={giftIcon} width={24} height={24} />
              Вознаграждение・{cost} ₽
            </Stack>
          </Label>
        : null
      }
    </Box>
  </Stack>
);
